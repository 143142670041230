.artWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    opacity: 1;
    background-color: white;
    transition: all 1s ease;
    cursor: pointer;
    margin-bottom: 60px;
    text-decoration: none;
    color: #404040;
    animation-name: opacityImage;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    &:hover:after {
        opacity: 1;
    }
    &:after {
        content: '\A';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        opacity: 0;
        transition: all 1s;
        -webkit-transition: all 1s;
    }

    .photo {
        width: 100%;
        object-fit: contain;
        max-height: 70vh;
    }

    .artName {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        text-align: center;
        text-decoration: none;
        color: #404040;
    }
}

.lazy-load-image-loaded {
    height: auto !important;
    .photo {
      height: auto !important;
    }
}

@keyframes opacityImage {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    margin-left: -60px;
    /* gutter size offset */
    width: auto;
}

.my-masonry-grid_column {
    padding-left: 60px;
    /* gutter size */
    background-clip: padding-box;
}

/* Style your items */

.my-masonry-grid_column>div {
    /* change div to reference your elements you put in <Masonry> */
    background: grey;
    margin-bottom: 60px;
}