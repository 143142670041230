.arts {
    margin: 1rem 2rem;

    .filter {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 0.5em;

        .title {
            padding-right: 1em;
            font-size: 1.2em;
        }

        .button {
            padding-right: 1em;
            cursor: pointer;
            color: #707070;

            &.is-active {
                color: #404040;
            }

            &:hover {
                color: black;
            }
        }
    }
}

.scroll-up-img {
    height: 40px;
    width: 40px;
    margin: auto;
}




