.mainPage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 72px);

    .logoWrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .logoLarge {
            width: 250px;
            height: 250px;
        }

        .slogan {
            font-size: 1.5em;
            text-decoration: none;
        }
    }

    a {
        text-decoration: none;
        color: black
    }
}