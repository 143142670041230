.aboutPage {
    margin: 2rem;
    .photo {
        height: 80vh;
        max-width: 500px;
        object-fit: cover;
        float: left;
        margin: 0 2rem 1rem 0;
    }
    .text {
        text-align: justify;
    }
}

@media only screen and (max-width: 480px) {
    .aboutPage {
        .photo {
            width: 100%;
            height: 60vh;
        }
    }
}

@media only screen and (min-width: 481px) and (max-width: 850px) {
    .aboutPage {
        .photo {
            float: none;
        }
    }
}