.artPage {
    display: flex;

    .descriptionWrapper {
        display: flex;
        flex-direction: column;
        margin: 1em 0 0 2em;
        width: 200px;
        flex: 0 0 auto;

        .artName {
            font-size: 1.2em;
            font-weight: 700;
            margin-bottom: 1em;
        }

        .textDescription {
            text-align: justify;
        }
        
        .seriesButton {
            font-weight: 600;
            cursor: pointer;
        }
    }

    .emptyBlock {
        width: 200px;
        flex: 0 0 auto;
        margin-right: 2em;
    }

    .photos {
        flex: 1 1 auto;
        width: calc(100vw - 400px - 4em);
        height: calc(100vh - 100px);
        overflow: hidden;
    }
}


@media only screen and (max-width: 767px) {
    .artPage { 
        flex-direction: column;
        .photos {
            margin-top: 1em;
            width: 100%;
            height: 70vh;
        }

        .emptyBlock {
            display: none;
        }
    }
}

@media only screen and (max-device-width: 767px) and (orientation: landscape) {
    .artPage {
        flex-direction: column;
        .photos {
            margin-top: 1em;
            width: 100%;
            height: 70vh;
        }

        .emptyBlock {
            display: none;
        }
    }
}



