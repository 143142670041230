
.swiper {
    height: 100%;
    width: 100%;
    --swiper-navigation-color: black;
    --swiper-theme-color: black;
}

.swiper-wrapper {
    height: 100%;
}

.swiper-slide {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.photo {
    object-fit: contain;
    max-height: 100%;
    max-width: 100%;
}


