.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 2.5em;
}

.nav-menu {
    display: flex;
    justify-content: flex-end;
    padding-inline-start: 0.5em;

    li {
        list-style: none;
        margin: 0;
        padding: .5em 1em;
        border-radius: 3px;
        text-decoration: none;
    }

    li:last-child {
        padding-right: 0;
    }

    a {
        text-decoration: none;
        font-size: 1.2em;
        color: #707070;

        &.is-active {
            color: #404040;
        }
    }

    a:hover {
        color: black;
    }
}

.logo {
    height: 3em;
    width: 3em;
    cursor: pointer;
}

