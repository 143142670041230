
@import "~react-image-gallery/styles/css/image-gallery.css";
@font-face {
  font-family: "T-star-regular";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/T-Star-regular-webfont.woff.ttf");
}
@font-face {
  font-family: "T-star-pro";
  font-style: normal;
  font-weight: 300;
  src: url("./assets/fonts/T-StarPro.otf");
}
@font-face {
  font-family: "T-star-pro";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/T-StarPro-Bold.otf");
}

body {
  margin: 0;
  font-family: 
    T-star-pro,
    T-star-regular,
    Roboto,
    Arial,
    Helvetica,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #707070;
  background-color: white;
  font-size: 16px;
  --swiper-theme-color: #fc6203;
}

@media only screen and (max-width: 480px) {
  body {
    font-size: 12px;
  }
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
  font-family: T-star-regular, Roboto, Arial, Helvetica, sans-serif;
}
