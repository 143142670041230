.contactsPage {
    margin: 2rem;
    .links {
        margin-top: 1rem;
        .link {
            a {
                text-decoration: none;
                cursor: pointer;
                color: #404040;

                &:hover {
                    color: black;
                }
            }
        }
    }
}